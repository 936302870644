@import "../../common.scss";

.topmenu {
    position: relative;
    z-index: 1000;
    margin: 0 0 1rem 0;
    background: $headerFooterBackground;
    ul.sub-menu {
        display: none;
    }
    ul.main-menu {
        background: $headerFooterBackground;
        color: $headerFontColor;
        list-style: none;
        // display: inline-block;
        display: flex;
        margin: 0;
        padding: 0;
        width: 100%;
        border-bottom: solid 2px $primaryBG;
        li {
            margin: 0;
            padding: 0;

            // display: inline;

            &.mobile-menu-button {
                display: inline;
                font-size: 2rem;
                padding: 0 1rem;
                cursor: pointer;
            }
            span, a {
                display: block;
                color: $primaryBG;
                cursor: pointer;
                text-decoration: none;
                padding: .5rem .75rem;
                font-size: .8rem;
                border-top-left-radius: .5rem;
                border-top-right-radius: .5rem;
                margin: .25rem .125rem 0 0;
                white-space: nowrap;
                min-height: 2.5rem;
                &:hover {
                    background: $primaryBGHover;
                    color: $primaryTextColor;
                }

                &.current {
                    background: $primaryBG;
                    color: $headerFooterBackground;

                    color: $primaryTextColor;
                }
            }

            &:first-child {
                a {
                    border-left: 0;
                }
            }
            &.logo {
                // float: right;
                text-align: right;
                margin-left: auto;
                a {
                    border: 0 !important;
                    margin: 0;
                    &:hover {
                        background-color: $bodyBackground;
                    }
                    svg {
                        height: 1.45rem;
                        width: auto;
                    }
                }
            }
        }

        // margin: 0 0 1rem 0;
    }

}
.mobile-menu {

    ul {
        padding-left: 0rem;
        li {
            display: block;
            a {
                text-decoration: none;
                border: solid 1px $primaryBG;
                padding: .5rem 1rem;
                display: block;
                margin: 0 0 .5rem 0;
                border-radius: .5rem;
                &.current {

                    color: $headerFooterBackground;
                    background: $primaryBG;
                    color: $primaryTextColor;
                }

            }
            .sub-menu {
                display: block !important;
                list-style: none !important;
                padding: 0 !important;
                margin: 0 0 0 2rem !important;
                li {
                     a {
                        padding: .125rem 1rem !important;
                     }
                }
            }
        }
    }
}

body.desaturated {
    .mobile-menu {
        border-right: solid 2px $desaturatedPrimaryBG;
    }

    .mobile-menu,
    .topmenu {
        ul.sub-menu {
            li {
                a, span {
                    text-decoration: none;
                    color: $desaturatedPrimaryBG;
                    border-right: solid 2px $desaturatedPrimaryBG;
                    border-top: solid 2px $desaturatedPrimaryBG;
                    border-bottom: solid 2px $desaturatedPrimaryBG;
                    border-left: solid 2px $desaturatedPrimaryBG;
                    &.current {
                        background: $desaturatedPrimaryBG;
                        color: $desaturatedPrimaryTextColor;
                        border-right: solid 2px $desaturatedPrimaryBG;
                        border-top: solid 2px $desaturatedPrimaryBG;
                        border-bottom: solid 2px $desaturatedPrimaryBG;
                        border-left: solid 2px $desaturatedPrimaryBG;
                    }
                    &:hover {
                        background: $desaturatedPrimaryBGHover;
                        color: $desaturatedPrimaryTextColor;
                        border-right: solid 2px $desaturatedPrimaryBG;
                        border-top: solid 2px $desaturatedPrimaryBG;
                        border-bottom: solid 2px $desaturatedPrimaryBG;
                        border-left: solid 2px $desaturatedPrimaryBG;
                    }
                }
            }
        }
        ul.main-menu {
            li {
                display: block;
                a, span {
                    text-decoration: none;
                    color: $desaturatedPrimaryBG;
                    border-right: solid 2px $desaturatedPrimaryBG;
                    border-top: solid 2px $desaturatedPrimaryBG;
                    border-bottom: solid 2px $desaturatedPrimaryBG;
                    border-left: solid 2px $desaturatedPrimaryBG;
                    &.current {
                        background: $desaturatedPrimaryBG;
                        color: $desaturatedPrimaryTextColor;
                        border-right: solid 2px $desaturatedPrimaryBG;
                        border-top: solid 2px $desaturatedPrimaryBG;
                        border-bottom: solid 2px $desaturatedPrimaryBG;
                        border-left: solid 2px $desaturatedPrimaryBG;
                    }
                    &:hover {
                        background: $desaturatedPrimaryBGHover;
                        color: $desaturatedPrimaryTextColor;
                        border-right: solid 2px $desaturatedPrimaryBG;
                        border-top: solid 2px $desaturatedPrimaryBG;
                        border-bottom: solid 2px $desaturatedPrimaryBG;
                        border-left: solid 2px $desaturatedPrimaryBG;
                    }
                }
            }
        }
    }
    .topmenu {
        ul.main-menu {
            border-bottom: solid 2px $desaturatedPrimaryBG;
        }
    }
}

body.retro {
    .mobile-menu {
        border-right: dotted 2px $retroPrimaryBG;
    }
    .mobile-menu,
    .topmenu {
        ul.sub-menu {
            li {
                a {
                    font-size: .55rem;
                    color: $retroPrimaryBG;
                    background: $headerFooterBackground;
                    border-right: dotted 2px $headerFooterBackground;
                    border-top: dotted 2px $headerFooterBackground;
                    border-bottom: dotted 2px $headerFooterBackground;
                    border-left: dotted 2px $headerFooterBackground;
                    &.current {
                        // background: $retroPrimaryBG;
                        // color: $retroPrimaryTextColor;
                        border-right: dotted 2px $retroPrimaryBG;
                        border-top: dotted 2px $retroPrimaryBG;
                        border-bottom: dotted 2px $retroPrimaryBG;
                        border-left: dotted 2px $retroPrimaryBG;
                        color: $colorWhite;
                    }
                    &:hover {
                        // background: $retroPrimaryBGHover;
                        // color: $retroPrimaryTextColo r;
                        color: $retroPrimaryBGHover;
                        border-right: dotted 2px $retroPrimaryBGHover;
                        border-top: dotted 2px $retroPrimaryBGHover;
                        border-left: dotted 2px $retroPrimaryBGHover;
                        border-bottom: dotted 2px $retroPrimaryBGHover;
                    }
                }
            }
        }
        ul.main-menu {
            li {
                display: block;
                a, span {
                    font-size: .55rem;
                    color: $retroPrimaryBG;
                    background: $headerFooterBackground;
                    border-right: dotted 2px $headerFooterBackground;
                    border-top: dotted 2px $headerFooterBackground;
                    border-bottom: dotted 2px $headerFooterBackground;
                    border-left: dotted 2px $headerFooterBackground;
                    &.current {
                        // background: $retroPrimaryBG;
                        // color: $retroPrimaryTextColor;
                        border-right: dotted 2px $retroPrimaryBG;
                        border-top: dotted 2px $retroPrimaryBG;
                        border-bottom: dotted 2px $retroPrimaryBG;
                        border-left: dotted 2px $retroPrimaryBG;
                        color: $colorWhite;
                    }
                    &:hover {
                        // background: $retroPrimaryBGHover;
                        // color: $retroPrimaryTextColo r;
                        color: $retroPrimaryBGHover;
                        border-right: dotted 2px $retroPrimaryBGHover;
                        border-top: dotted 2px $retroPrimaryBGHover;
                        border-left: dotted 2px $retroPrimaryBGHover;
                        border-bottom: dotted 2px $retroPrimaryBGHover;
                    }
                }

                &.logo {
                    a {
                        svg {
                            height: 1.25rem;
                            width: auto;
                            // max-width: 30%;
                        }
                    }
                }
            }
        }
    }
    .topmenu {
        ul.main-menu {
            border-bottom: dotted 2px $retroPrimaryBG;
        }
    }
    .mobile-menu {
        ul.main-menu {
            li {
                a, span {
                    font-size: 1rem;
                }
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .mobile-menu {
        display: none !important
    }
    .topmenu {
        .sub-menu {
            display: block !important;
            margin-top: -.25rem;
            padding-bottom: .5rem;
            li {
                margin: 0;
                padding: 0;

                // display: inline;

                span, a {
                    display: inline-block;
                    color: $primaryBG;
                    cursor: pointer;
                    text-decoration: none;
                    padding: .5rem .75rem;
                    font-size: .8rem;
                    border-bottom-left-radius: .5rem;
                    border-bottom-right-radius: .5rem;
                    margin: .25rem .125rem 0 0;
                    white-space: nowrap;
                    min-height: 2.5rem;
                    &:hover {
                        background: $primaryBGHover;
                        color: $primaryTextColor;
                    }

                    &.current {
                        background: $primaryBG;
                        color: $headerFooterBackground;

                        color: $primaryTextColor;
                    }
                }

                &:first-child {
                    a {
                        border-left: 0;
                    }
                }
                &.logo {
                    // float: right;
                    text-align: right;
                    margin-left: auto;
                    a {
                        border: 0 !important;
                        margin: 0;
                        &:hover {
                            background-color: $bodyBackground;
                        }
                        svg {
                            height: 1.45rem;
                            width: auto;
                        }
                    }
                }
            }
        }

    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .topmenu {
        ul.main-menu {
            li {
                a, span {
                    font-size: 1rem;
                }
            }
        }
    }

    body.retro {
        .mobile-menu,
        .topmenu {
            ul.main-menu,
            ul.sub-menu {
                li {
                    display: block;
                    a, span {
                        font-size: 1rem;
                    }
                    &.logo {
                        a {
                            svg {
                                height: 1.75rem;
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}