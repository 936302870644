body {
    header {
        .header-message {
            text-align: center;
            color: #0f0;
        }
    }
    .print-footer { display: none};
    .print-cards {
        h2 {
            text-align: center;
            padding: 0 5%;
            margin: 0;
            .units-summary {
                float: right;
                font-size: 1rem;
            }
        }
        background: #ffffff;
        width: 100%;
        .print-section {
            border: none !important;
            width: 100%;
            display: block;

            // &:first-child {
            //     padding-top: 0;
            // }

            .section-content {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                // display: flex;
                // flex-wrap: wrap;
                text-align: center;
                // justify-content: center;
                border: none !important;
                .lance-bonus {
                    font-size: 1rem;
                }
                .card {
                    border: none !important;
                    margin: .5rem;


                    max-width: 3.5in;
                    width: 3.5in;

                }
            }
            &.big-version {
                display: block;
                text-align: center;
                padding-top: .25in;

                .section-content {
                    display: block;

                    .card {
                        // width: 35%;
                        display: inline-block;
                        max-width: 100%;
                        // max-height: 45vh;
                        margin: 1rem;
                        width: 45%;
                    }
                }

                page-break-after: always;
            }
        }
    }
}

@media print {
    @page {
        margin: 0;
        // size: landscape;
    }

    header {
        display: none !important;
    }
    // .print-header {
    //     display: block !important;
    //     margin-bottom: .25in;
    //     position: absolute;
    //     top: .25in;
    //     width: 100%;

    // }
    .print-cards {
        position: relative;
        // padding: .25in;
        .print-section {
            // page-break-after: always;
            break-inside: avoid;
            .section-content {
                // break-inside: avoid;
                // page-break-after: always;

                .lance-bonus {
                    font-size: .9rem !important;
                }
                .card {
                    break-inside: avoid;
                    background-color: #fff !important;
                }
            }
            &:last-child {
                .section-content {
                    page-break-after: avoid;
                }
            }
            &.big-version {
                @page {
                    margin: 0;
                    size: landscape;
                }
                break-inside: auto;
                .section-content {
                    break-inside: auto;
                    .lance-bonus {
                        font-size: .9rem !important;
                    }
                    .card {
                        break-inside: avoid;
                        background-color: #fff !important;
                    }
                }
                &:last-child {
                    .section-content {
                        page-break-after: avoid !important;
                    }
                }
            }


        }

        .print-footer {
            display: block !important;
            position: fixed;
            bottom: .25in;
            left: 0;
            padding: 0 .5in;

            width: 100%;
            border-top: solid 1px #000 !important;
            p {
                font-size: .5rem;
                margin: 0;
            }
            .print-logo {
                background: #000;
                padding: .25rem 1rem;
                float: right;
                -webkit-print-color-adjust: exact;
                print-color-adjust: exact;
                //color-adjust: exact;
            }
        }
    }
}