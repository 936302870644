@import "../../common.scss";

.mech-creator-status-bar {
    z-index: 1024;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: solid 2px $primaryBG;
    color: $fontColor;
    background: $headerFooterBackground;
    text-align: center;
    padding: 0 .125rem;
    font-size: .9rem;
}

body.desaturated {
    .mech-creator-status-bar {
        border-top: solid 2px $desaturatedPrimaryBG;
        color: $desaturatedFontColor;
    }
}

body.retro {
    .mech-creator-status-bar {
        border-top: dotted 2px $retroPrimaryBG;
        color: $retroFontColor;
    }
}