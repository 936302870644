@import "../../../../common.scss";

body {
    .mech-selector {
        // position: absolute;
        // // height: 100%;
        // top: 0;
        // right: 0;
        // // background: $primaryBG;
        // // color: $primaryTextColor;
        // padding-top: 3rem;
        // width: 16vw;

        .details {
            display: none
        }

        // &.expanded {
        //     .details {
        //         display: block;
        //     }
        // }
        .lance-name {
            color: $primaryBG;
            text-align: center;
        }
        .mech-list {
            list-style: none;
            margin: 0;
            padding: 0;
            overflow: hidden;
            li {
                margin: 0;
                padding: 0;
                .stats {
                    position: relative;
                    height: 25px;
                    .move {
                        width: 20%;
                        display: inline-block;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                    .bars {
                        width: 80%;
                        display: inline-block;
                        padding-left: 2px;
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
            }
        }

    }
    .selected-mech {
        max-width: 100%;
        .text-section {
            max-width: 50%;
            margin: 10rem auto 0 auto;
            padding: 3rem 0;
        }
    }
    .turn-counter{
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        .grow-1{
            text-align: center;
            flex-grow: 1;
        }
        .grow-2{
            text-align: center;
            flex-grow: 2;
        }
    }
    .table {
        tbody.highlighted {
            background: $primaryBG !important;
            tr {
                background: $primaryBG !important;
                td {
                    background: $primaryBG !important;
                }
            }

        }
    }

    .page-container {
        display: flex;
        .record-sheet-column {
            width: 60vw;

        }

        .sidebar {
            width: 40vw;
        }
    }

    .svg-height-100perc > svg {
        height: 100% !important;
        width: auto !important;
        max-width: 100% !important;
        max-height: 100% !important;
    }
}

@media (min-width: 744px) {

    body {
        .page-container {
            .record-sheet-column {
                width: 75vw;

            }

            .sidebar {
                width: 25vw;
            }
        }
    }
}

@media (min-width: 768px) {

    body {
        .page-container {
            .record-sheet-column {
                width: 83vw;

            }

            .sidebar {
                width: 17vw;
            }
        }
    }
}

@media (min-width: 992px) {
    body {
        .page-container {
            .record-sheet-column {
                width: 85vw;

            }

            .sidebar {
                width: 15vw;
            }
        }
    }
}