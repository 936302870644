@import "../../common.scss";

.sidebar-menu {
    list-style: none;
    margin: 0 0 40px 0;
    padding: 0;
    li {
        a {
            display: block;
            .title {
                font-size: 1.2rem;
            }
            .subtitle {
                font-size: .8rem;
            }
        }
    }

    .btn-lightbg {
        background-color: $lightBG;
        border-color: $lightBG;
        color: $bodyBackground !important;
        &:hover {
          background-color: $lightBGHover;
          border-color: $lightBGHover;
          color: $bodyBackground !important;
        }
        &:disabled {
          background-color: #222;
          border-color: #000;
          color: 0;
        }
      }
}

body.desaturated {
  .sidebar-menu {
    .btn-lightbg {
      background-color: $desaturatedLightBG;
      border-color: $desaturatedLightBG;
      color: $bodyBackground !important;
      &:hover {
        background-color: $desaturatedLightBGHover;
        border-color: $desaturatedLightBGHover;
        color: $bodyBackground !important;
      }
      &:disabled {
        // background-color: #222;
        // border-color: #000;
        // color: 0;
      }
    }
  }
}

body.retro {
  .sidebar-menu {
    .btn-lightbg {
      background-color: $headerFooterBackground;
      border: dotted 2px $retroPrimaryBGHover;
      color: $retroPrimaryBGHover !important;
      &:hover {
        background-color: $retroPrimaryBGHover;
        border: dotted 2px $retroLightBGHover;
        color: $headerFooterBackground !important;
      }
      &:disabled {
        // background-color: #222;
        // border-color: #000;
        // color: 0;
      }
    }
  }
}