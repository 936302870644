body {
    .summary-page {
        min-height: 11in;
        width: 8.5in;
        background: #fff;
        margin: 1rem;
        max-width: 90%;
        padding: .125in;
        position: relative;

        .print-footer {
            display: block !important;
            position: absolute;
            bottom: .25in;
            left: 0;
            padding: 0 .5in;

            width: 8.5in;
            border-top: solid 1px #000 !important;
            p {
                font-size: .5rem;
                margin: 0;
            }
            .print-logo {
                background: #000;
                padding: .25rem 1rem;
                float: right;
                -webkit-print-color-adjust: exact;
                print-color-adjust: exact;
                //color-adjust: exact;
            }
        }
    }

    .page {
        height: 11in;
        width: 8.5in;
        background: #fff;
        margin: 1rem;
        max-width: 90%;
        padding: .125in;
        svg {
            max-height: 10.75in;
            max-width: 8.25in;
        }
    }

    .print-table {
        border-top: solid 1px #000;
        border-left: solid 1px #000;
        thead {
            tr {
                th {
                    background: #ddd;
                    border-bottom: solid 1px #000;
                    border-right: solid 1px #000;
                    padding: 0 .25rem;
                    &.no-right-border {
                        border-right: none !important;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    border-bottom: solid 1px #000;
                    border-right: solid 1px #000;
                    padding: 0 .25rem;
                }
            }
        }
    }
}

@media print {
    body {
        .summary-page {
            max-width: 100% !important;
            margin: 0 !important;
            padding: 0 .125in !important;
        }
        .page {
            max-width: 100% !important;
            margin: 0 !important;
            page-break-inside: avoid;
            padding: 0 .125in !important;
        }
    }
}