@import "./common.scss";

@font-face {
  font-family: 'DotMatrix';
  src: url('./fonts/DotMatrixRegular.woff2') format('woff2'),
      url('./fonts/DotMatrixRegular.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

body {
  #root {
    min-height: 100%;
  }
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bodyBackground;
}

.auto-width {
  width: auto;
  display: inline-block;
}

.icon-spin {
  animation: rotation 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.overflow-scroll-y {
  overflow-y: scroll;
}

.overflow-scroll-x {
  overflow-x: scroll;
}

.overflow-scroll {
  overflow: scroll;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.icon-links {
  display: flex;

  a {
    margin: 1rem;
    // width: 5rem;
    min-height: 5rem;
    max-width: 10rem;
    display: block;
    text-align: center;
    svg {
      height: 4rem;
      width: 4rem;
      margin: 0 auto;
      display: block;
    }
  }

}

.row .smaller-padding {
  padding-right: calc(var(--bs-gutter-x) * 0.125);
  padding-left: calc(var(--bs-gutter-x) * 0.125);
}

.text-center {
  text-align: center;
}
.font-weight-normal {
  font-weight: normal !important;
}
.font-weight-700 {
  font-weight: 700 !important;
}
.font-weight-100 {
  font-weight: 100 !important;
}
a {
  color: $primaryBG;
  outline: none !important;
  &:hover {
    color: $primaryBGHover;
  }
}

.mech-tro {
  // background: $darkenedBG;
  color: $fontColor;
  width: 100%;
  // padding: .5rem;
  // background-color: $darkenedBG;

  &.some-padding {
    padding: 1rem;
  }

  border: none;
  font-size: 12px;
  tbody {
      border: none;
      background: transparent !important;
  }
  thead {
      background: transparent !important;
      border: 0 !important;
  }
  tr {
      td, th {
          margin: auto;
          padding: 0 5px 0 0;
          // background: $bodyBackground;
          background: transparent;
          color: $fontColor;

      }
      th {
          vertical-align: bottom;
          font-weight: 700;
      }
      td {
          vertical-align: top;
      }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: stretch;
  .grow {
    flex-grow: 1;
  }
  .grow-1 {
    flex-grow: 1;
  }
  .grow-2 {
    flex-grow: 2;
  }
  .with-margins {
    margin: .5rem;
  }
}

@media (min-width: 768px) {
  body {
    .flex {
      .large-max-width-50 {
        max-width: 45%;
        min-width: 45%;
      }
      .large-max-width-100 {
        max-width: 91%;
        min-width: 91%;
      }
    }
  }
}

textarea.taller {
  height: 500px;
}
label.taller textarea {
  height: 500px;
}
select, input, label, textarea {
  width: 100%;
  display: block;
}
input[type=checkbox] {
  width: auto;
  display: inline-block;
}
select,
textarea,
input[type=text],
input[type=search],
input[type=number] {
  padding: 0.125rem .5rem;
  border: solid 2px #000;
  outline: none;
  background: $colorTan;
  &:focus {
    border: solid 2px $primaryBG;
    background: #fff;
  }
}
.fieldset {
  border: solid 2px $primaryBG;
  margin: 1rem 0 .5rem 0;
  padding: .5rem;
  legend {
    background: $primaryBG;
    color: $primaryTextColor;
    padding: 0 .5rem;
    border-radius: .5rem;
    display: inline-block;
    width: auto;
    margin: -1.25rem 0 .5rem 0rem;
    font-size: 1rem;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.ui-page {
  background-color: $bodyBackground;
  width: 100%;
  color: $fontColor;
  position: relative;

  .width-90 {
    width: 90%;
    display: inline-block;
  }

  .width-80 {
    width: 80%;
    display: inline-block;
  }

  .width-50 {
    width: 50%;
    display: inline-block;
  }

  .width-40 {
    width: 40%;
    display: inline-block;
  }

  .mobile-menu {
    width: 300px;
    left: -302px;
    border-right: solid 2px $primaryBG;
    background: $headerFooterBackground;
    transition: left .5s;
    height: 100vh;
    top: 0;
    position: fixed;
    padding: 4rem 1rem;
  }

  .content {
    position: absolute;
    left: 0;
    top: 5rem;
    padding: 0 0.5rem 5rem 0.5rem;
    transition: left .5s;
    width: 100%;
    overflow-x: hidden;
  }

  &.show-mobile {
    height: 100vh;
    overflow: hidden;
    .mobile-menu {
      left: 0;
    }
    .content {
      left: 302px;
    }
  }
  table {
    thead, tbody, tfoot {
      tr {
        th, td {
          vertical-align: top;
          color: $fontColor;
        }
      }
    }
  }
}

.modal {
  // &.modal-lg {

  // }
  padding-left: 0 !important;
  &.modal-xl {
    width: 100%;
    max-width: 100%;
    .modal-dialog {
      max-width: 95vw;
      width: 95vw;
      margin: 1rem auto;
      .modal-content {
        min-height: 95vh;
      }
    }
  }
  &.modal-xxl {
    width: 100%;
    max-width: 100%;
    .modal-dialog {
      max-width: 100vw;
      width: 100vw;
      margin: 0;
      .modal-content {
        min-height: 100h;
      }
    }
  }
  &.no-content-padding {
    .modal-dialog {
      .modal-content {
        .modal-body {
          padding: 0 !important;
        }
      }
    }
  }
}

.modal-backdrop.show {
  opacity: 0.8;
}

.search-sort-wrapper {
    display: flex;
    justify-content: space-around;
    padding-bottom: 0.5rem;

    span {
        display: flex;
        align-items: center;

        label { padding-left: 1rem; }
    }
}

// .table-wrapper {
//   overflow-x: auto;
// }

.table {
  border-top: solid 1px $colorWhite;
  thead, tfoot {
    th, td {
      background: $headerFooterBackground;
    }
  }

  tfoot, tbody, thead {
    tr {
      th, td {
        vertical-align: top;
        border-top: 0;
        box-shadow: none !important;
        border: none !important;
        padding: .25rem .5rem;
      }
    }
  }

  tbody {
    border-bottom: solid 1px $colorWhite !important;
    border-top: none !important;

    tr td {
      background: #222;
    }

    &:nth-child(odd) tr td {
      background: #444;
    }
  }


  thead, tbody, tfoot {
    tr {
      td, th {
        color: $textSectionFG;

      }
      td.alt-bg {
        background: rgba(0,0,0,.4);
      }

      td.selected-cell {
        background: rgba(0,0,0,.4);
      }

      &.disabled {
        td, th {
          color: $disabledTextColor;

        }
      }
    }

  }

  &.tighter-padding {
    tbody, thead, tfoot {
      tr {
        td, th {
          padding: .125rem .25rem;

        }
      }
    }
  }
}

.color-green {
  color: $colorGreen;
}

.color-blue {
  color: $colorBlue;
}
.color-red {
  color: $colorRed;
}
.color-gray {
  color: #666
}
.color-light-gray {
  color: #ccc
}
.color-brighter-red {
  color: $colorBrighterRed;
}
.color-bright-red {
  color: $colorBrightRed;
}
.bright-red-strike-through {
  text-decoration: line-through;
  text-decoration-color: currentcolor;
  text-decoration-thickness: auto;
  text-decoration-color: $colorBrightRed;
  text-decoration-thickness: 3px;
}

.damaged-tag {
  display: inline-block;
  background: $colorBrightOrange;
  color: $colorWhite;
  padding: .25rem 1rem;
  border-radius: .25rem;
}

.wrecked-tag {
  display: inline-block;
  background: $colorBrightRed;
  color: $colorWhite;
  padding: .25rem 1rem;
  border-radius: .25rem;
}

.lr-padding {
  padding-right: .5rem !important;
  padding-left: .5rem !important;
}
.lr-margin {
  margin-right: .5rem !important;
  margin-left: .5rem !important;
}
.small-pt-text {
  font-size: .7rem;
}
.overflow-hidden {
  overflow: hidden;
}
.clear-both {
  clear: both;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.3rem;
}

h4 {
  font-size: 1.1rem;
}
.text-section {
  // border-top-left-radius: .5rem;
  // border-top-right-radius: .5rem;
  border-radius: .5rem;
  margin: 0 0 1rem 0;
  // overflow: hidden;
  border: solid 2px $primaryBG;
  background: $textSectionBG;
  color: $textSectionFG;
  h2 {
      background: $headerFooterBackground;
      color: $headerFontColor;
      border-top-right-radius: .5rem;
      border-top-left-radius: .5rem;
      border-bottom: solid 2px $primaryBG;
      margin: 0 0 1rem 0;
      padding: 0 1rem .125rem 1rem;
  }
  .section-content {
      padding: 0 .5rem;
  }
}

// Bootstrap overrrides
body {
  .inline-block {
    display: inline-block
  }

  .width-auto {
    width: auto;
  }
  hr {
    height: 0;
    outline: 0;
    border: 0;
    padding: 0;
    margin: .5rem 0;
    border-bottom: solid 2px $primaryBG;
  }

  .no-wrap {
    white-space: nowrap;
  }
  svg {
    // max-height: 80vh;
    max-width: 90vw;

    &.spin {
      -webkit-animation:spin 2s linear infinite;
      -moz-animation:spin 2s linear infinite;
      animation:spin 2s linear infinite;
    }
  }
  @-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }
  }
  .no-right-margin {
    margin-right: 0 !important;
  }
  .no-left-margin {
    margin-left: 0 !important;
  }
  .no-top-margin {
    margin-top: 0 !important;
  }
  .no-bottom-margin {
    margin-bottom: 0 !important;
  }
  .min-width {
    width: 1%;
  }
  .drop-down-menu-container {
    position: relative;
    display: inline-block;
    .dd-menu {
      display: none;
      position: absolute;
      top: 2rem;
      left: 0;
      border: solid 1px $primaryBGHover;
      z-index: 1000;
      &.active {
        display: inline-block;
      }

      li {
        text-align: left;
        background: $textSectionBG;
        color: $primaryTextColor;
        border-bottom: solid 1px $primaryBGHover;
        cursor: pointer;
        padding: .125rem .5rem;
        margin: auto;
        &:hover {
          background-color: $primaryBG;
          color: $primaryTextColor;
          outline: none !important;

        }
      }
    }
  }
  .btn {
    outline: none !important;
    box-shadow: none !important;
    margin-right: .25rem;
    margin-left: .25rem;
    margin-bottom: .25rem;
    color: $primaryTextColor;
    &:hover {
      color: $primaryTextColor;
    }
    &.more-margin {
      margin-right: .5rem;
      margin-left: .5rem;
      margin-bottom: .5rem;
    }
    &.no-margin {
      margin: 0;
    }
    &.margin-right {
      margin-right: 1rem !important;
    }
    &.btn-xs {
      font-size: 0.7rem;
      line-height: 1;
      padding: .25rem;
    }
  }

  .small-margins {
    margin: 0 .5rem .5rem .5rem;
  }

  .btn-primary {
    background-color: $primaryBG;
    border-color: $primaryBG;
    color: $primaryTextColor;
    > svg {
      stroke: $primaryTextColor !important;
      color: $primaryTextColor !important;
      fill: $primaryTextColor !important;
    }
    &:active {
      background-color: $primaryBG !important;
      border-color: $primaryBG !important;
      color: $primaryTextColor !important;
    }
    &:focus {
      background-color: $primaryBG !important;
      border-color: $primaryBG !important;
      color: $primaryTextColor !important;
    }
    &:hover {
      background-color: $primaryBGHover;
      border-color: $primaryBGHover;
      color: $primaryTextColor;
    }
    &:disabled {
      background-color: #222;
      border-color: #000;
      color: 0;
    }

  }
  .btn-sm {
    > svg {
      height: 1em;
      width: 1em;
    }
  }
  .btn-xs {
    > svg {
      height: .7em;
      width: .7em;
    }
  }

  .btn-md {
    > svg {
      height: 1.5em;
      width: 1.5em;
    }
  }

  .btn-secondary {
    background-color: $secondaryBG;
    border-color: $secondaryBG;
    color: $primaryTextColor;
    > svg {
      stroke: $primaryTextColor !important;
      color: $primaryTextColor !important;
      fill: $primaryTextColor !important;
    }
    &:active {
      background-color: $secondaryBG !important;
      border-color: $secondaryBG !important;
      color: $primaryTextColor !important;
    }
    &:focus {
      background-color: $secondaryBG !important;
      border-color: $secondaryBG !important;
      color: $primaryTextColor !important;
    }
    &:hover {
      background-color: $secondaryBGHover;
      border-color: $secondaryBGHover;
      color: $primaryTextColor;
    }
    &:disabled {
      background-color: #222;
      border-color: #000;
      color: 0;
    }
  }
  .btn-phase-active {
    background-color: #0000AA;
    border-color: #0000AA;
    color: $primaryTextColor;
    > svg {
      stroke: $primaryTextColor !important;
      color: $primaryTextColor !important;
      fill: $primaryTextColor !important;
    }
    &:active {
      background-color: #0000AA !important;
      border-color: #0000AA !important;
      color: $primaryTextColor !important;
    }
    &:focus {
      background-color: #0000AA !important;
      border-color: #0000AA !important;
      color: $primaryTextColor !important;
    }
    &:hover {
      background-color: #0000CC;
      border-color: #0000CC;
      color: $primaryTextColor;
    }
    &:disabled {
      background-color: #224;
      border-color: #004;
      color: 0;
    }
  }
  .no-margins {
    margin: 0 !important;
  }

  .no-rl-margins {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ul.styleless {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      margin: 0;
      padding: 0;
    }
  }
  .modal-dialog {
    .modal-footer {
      background: $headerFooterBackground;
    }
    .modal-header {
      background: $headerFooterBackground;
      padding: .25rem 1rem;
      .close {
        color: $primaryBG;
        text-shadow: none;
        opacity: 1;
        &:hover {
          color: $primaryBGHover;
        }
      }
    }
    .modal-content {
      background: $bodyBackground;
      border: solid 2px $primaryBG;
      color: $fontColor;
      // max-height: 80vh;
      svg {
        max-height: 65vh;
      }
    }
  }

  .checkbox-input {
    cursor: pointer;
  }
  .pull-right,
  .float-right {
    float: right;
  }

  .pull-left,
  .float-left {
    float: left;
  }

  .position-relative {
    position: relative;
  }

  .display-block {
    display: block;
  }

  .full-width {
    width: 100%;
  }

  .alert {
    overflow: hidden;
    a {
        color: #000;
        &:hover {
            color: #333;
        }
    }

    .alert-countdown {
        position: absolute;
        top: -.5rem;
        right: 1.5rem;
        width: 90%;
        text-align: right;
        display: block;
        .alert-countdown-bar {
            display: inline-block;
            -webkit-transition: width 1s ease;
            -moz-transition: width 1s ease;
            -ms-transition: width 1s ease;
            transition: width 1s ease;
            background-color: $bodyBackground;
            height: 5px;
            width: 100%;
            &.dismiss-time-1 {
                -webkit-transition: width 1s ease;
                -moz-transition: width 1s ease;
                -ms-transition: width 1s ease;
                transition: width 1s ease;
            }
            &.dismiss-time-2 {
                -webkit-transition: width 2s ease;
                -moz-transition: width 2s ease;
                -ms-transition: width 2s ease;
                transition: width 2s ease;
            }
            &.dismiss-time-3 {
                -webkit-transition: width 3s ease;
                -moz-transition: width 3s ease;
                -ms-transition: width 3s ease;
                transition: width 3s ease;
            }
            &.dismiss-time-4 {
                -webkit-transition: width 4s ease;
                -moz-transition: width 4s ease;
                -ms-transition: width 4s ease;
                transition: width 4s ease;
            }
            &.dismiss-time-5 {
                -webkit-transition: width 5s ease;
                -moz-transition: width 5s ease;
                -ms-transition: width 5s ease;
                transition: width 5s ease;
            }
            &.dismiss-time-10 {
                -webkit-transition: width 10s ease;
                -moz-transition: width 10s ease;
                -ms-transition: width 10s ease;
                transition: width 10s ease;
            }
            &.dismiss-time-15 {
                -webkit-transition: width 15s ease;
                -moz-transition: width 15s ease;
                -ms-transition: width 15s ease;
                transition: width 15s ease;
            }
            &.dismiss-time-30 {
                -webkit-transition: width 30s ease;
                -moz-transition: width 30s ease;
                -ms-transition: width 30s ease;
                transition: width 30s ease;
            }
            &.dismiss-time-60 {
                -webkit-transition: width 60s ease;
                -moz-transition: width 60s ease;
                -ms-transition: width 60s ease;
                transition: width 60s ease;
            }
        }

    }

    p {
        color: #333;
    }
    a {
      text-decoration: underline;
    }
    &.alert-fade {
        -webkit-transition: height .2s ease;
        -moz-transition: height .2s ease;
        -ms-transition: height .2s ease;
        transition: height .2s ease;

        -webkit-transition: padding .2s ease;
        -moz-transition: padding .2s ease;
        -ms-transition: padding .2s ease;
        transition: padding .2s ease;

        -webkit-transition: border .2s ease;
        -moz-transition: border .2s ease;
        -ms-transition: border .2s ease;
        transition: border .2s ease;
        * {
            -webkit-transition: padding .2s ease;
            -moz-transition: padding .2s ease;
            -ms-transition: padding .2s ease;
            transition: padding .2s ease;
        }
    }
    &.fade-away {
        height: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        * {
            padding-bottom: 0px !important;
        }
        border-top: 0 !important;
        border-bottom: 0 !important;
    }
    // &.alert-out {
    //     height: 0px !important;
    // }
    &.alert-sm {
        padding: 0.45rem 1.25rem !important;
    }
  }
}

.print-bar {
  border-bottom: solid 2px $primaryBG;
  margin: 0 0 0 0;
  background: $headerFooterBackground;
  color: $headerFontColor;
  text-align: center;
}

.print-bg {
  background: #ccc;
  text-align: center;
  padding: 1rem;
  min-height: 96vh;
  .print-page {
    background: #fff;
    text-align: left;
    max-height: 9.74in;
    width: 7.5in;
    border: solid 2px #333;
    display: block;
    margin: 0 auto 1rem auto;
    max-width: 100%;
    svg {
      max-width: 100% !important;
      max-height: 100% !important;
    }
  }
}

body.desaturated {
  // * {
  //   -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  //   filter: grayscale(100%);
  // }
  .mech-tro {
    color: $desaturatedFontColor;
    tbody, thead, tfoot {
      tr {
        th, td {
          color: $desaturatedFontColor;
        }
      }
    }
  }
  svg.color-green {
    color: #999 !important;
    stroke: #999 !important;
  }
  button.btn-danger {
    color: #000 !important;
    stroke: #000 !important;
    fill: #ccc !important;
    background-color: #ccc !important;
    border-color: #ccc !important;
  }
  hr {
    border-color: $desaturatedPrimaryBG;
  }
  select,
  textarea,
  input[type=text],
  input[type=search],
  input[type=number] {

    background: $colorLightGray;
    &:focus {
      border: solid 2px $desaturatedPrimaryBG;
    }
  }
  p {

    strong {
      color: $desaturatedPrimaryBG;
    }

    a {
      color: $desaturatedPrimaryBG;
      text-decoration: underline;
      &:hover {
        color: $desaturatedPrimaryBGHover;
      }
    }
  }

  .alert {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }

  .fieldset {
    border-color: $desaturatedPrimaryBG;
    color: $desaturatedFontColor;
    legend {
      background-color: $desaturatedPrimaryBG;
    }
  }

  .text-section {
    border-color: $desaturatedPrimaryBG;
    h2 {
      border-color: $desaturatedPrimaryBG;
    }
    a {
      color: $desaturatedPrimaryBG;
      text-decoration: underline;
      &:hover {
        color: $desaturatedPrimaryBGHover;
      }
    }
  }

  .btn {
    // outline: none !important;
    // box-shadow: none !important;
    // margin-right: .25rem;
    // margin-left: .25rem;
    // margin-bottom: .25rem;
    text-decoration: none !important;
    color: $desaturatedPrimaryTextColor;
    &:hover {
      color: $desaturatedPrimaryTextColor;
    }
    // &.more-margin {
    //   margin-right: .5rem;
    //   margin-left: .5rem;
    //   margin-bottom: .5rem;
    // }
    // &.no-margin {
    //   margin: 0;
    // }
    // &.btn-xs {
    //   font-size: 0.7rem;
    //   line-height: 1;
    //   padding: .25rem;
    // }
  }

  // .small-margins {
  //   margin: 0 .5rem .5rem .5rem;
  // }

  .btn-primary {
    background-color: $desaturatedPrimaryBG !important;
    border-color: $desaturatedPrimaryBG !important;
    color: $desaturatedPrimaryTextColor !important;
    &:hover {
      background-color: $desaturatedPrimaryBGHover !important;
      border-color: $desaturatedPrimaryBGHover !important;
      color: $desaturatedPrimaryTextColor !important;
    }
    // &:disabled {
    //   background-color: #222;
    //   border-color: #000;
    //   color: 0;
    // }
    // .no-margins {
    //   margin: 0 !important;
    // }
  }

  // ul.styleless {
  //   margin: 0;
  //   padding: 0;
  //   list-style-type: none;
  //   li {
  //     margin: 0;
  //     padding: 0;
  //   }
  // }
  .btn-secondary {
    background-color: $desaturatedSecondaryBG !important;
    border-color: $desaturatedSecondaryBG !important;
    color: $desaturatedPrimaryTextColor !important;
    &:hover {
      background-color: $desaturatedSecondaryBGHover !important;
      border-color: $desaturatedSecondaryBGHover !important;
      color: $desaturatedPrimaryTextColor !important;
    }
    // &:disabled {
    //   background-color: #222;
    //   border-color: #000;
    //   color: 0;
    // }
  }

  .modal-dialog {
    // .modal-footer {
    //   background: $headerFooterBackground;
    // }
    .modal-header {
      // background: $headerFooterBackground;
      .close {
        color: $desaturatedPrimaryBG;
        &:hover {
          color: $desaturatedPrimaryBGHover;
        }
      }
    }
    .modal-content {
      border: solid 2px $desaturatedPrimaryBG;
    }
  }

}

body.retro {
  // * {
  //   -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  //   filter: grayscale(100%);
  // }

  background: $headerFooterBackground;
  font-size: .8rem;

  font-family: "DotMatrix" !important;
  .mech-tro {
    color: $retroFontColor;
    background: $headerFooterBackground;
    tbody, thead, tfoot {
      tr {
        th, td {
          background: $headerFooterBackground;
          color: $retroFontColor;
        }
      }
    }
  }
  hr {
    height: 0 !important;
    border-bottom: dotted 2px $retroPrimaryBG;
  }
  select,
  textarea,
  input[type=text],
  input[type=search],
  input[type=number] {

    background: $headerFooterBackground;
    color: $retroFontColor;
    border: solid 1px #fff;
    &:focus {
      color: #fff;
      background: darken($retroFontColor, 20);
    }
  }
  p {

    strong {
      color: $retroPrimaryBG;
    }

    a {
      color: $retroPrimaryBG;
      text-decoration: underline;
      &:hover {
        color: $retroPrimaryBGHover;
      }
    }
  }

  .fieldset {
    border: dotted 2px $retroPrimaryBG;
    color: $retroFontColor;
    legend {
      color: $retroPrimaryBG;
      background-color: $headerFooterBackground;
      border: dotted 2px $retroPrimaryBG;
    }
  }
  input[type=file] {
    background-color: $headerFooterBackground !important;
    border: dotted 2px #fff !important;
    color: #fff !important;
    padding: .5rem 1rem;
  }

  .news {
    li {
      border-top: dotted 2px $retroPrimaryBG;
    }
  }
  .text-section {
    border: dotted 2px $retroPrimaryBG;
    color: $retroFontColor;
    background-color: $headerFooterBackground;

    h2 {
      border-bottom: dotted 2px $retroPrimaryBG;
      color: $retroFontColor;
      font-size: 1.3rem;
      line-height: 2rem;
      padding: 0 1rem;
    }
    a {
      color: $retroPrimaryBG;
      text-decoration: underline;
      &:hover {
        color: $retroPrimaryBGHover;
      }
    }
  }

  .btn {
    // outline: none !important;
    // box-shadow: none !important;
    // margin-right: .25rem;
    // margin-left: .25rem;
    // margin-bottom: .25rem;
    text-decoration: none !important;
    color: #fff !important;
    &:hover {
      color: $retroPrimaryTextColor;
    }
    // &.more-margin {
    //   margin-right: .5rem;
    //   margin-left: .5rem;
    //   margin-bottom: .5rem;
    // }
    // &.no-margin {
    //   margin: 0;
    // }
    // &.btn-xs {
    //   font-size: 0.7rem;
    //   line-height: 1;
    //   padding: .25rem;
    // }
  }

  // .small-margins {
  //   margin: 0 .5rem .5rem .5rem;
  // }

  .btn-primary {
    background-color: $retroPrimaryBG !important;
    border-color: $retroPrimaryBG !important;
    color: #fff !important;
    &:hover {
      background-color: $retroPrimaryBGHover !important;
      border-color: $retroPrimaryBGHover !important;
      color: $retroPrimaryTextColor !important;
    }
    // &:disabled {
    //   background-color: #222;
    //   border-color: #000;
    //   color: 0;
    // }
    // .no-margins {
    //   margin: 0 !important;
    // }
  }

  // ul.styleless {
  //   margin: 0;
  //   padding: 0;
  //   list-style-type: none;
  //   li {
  //     margin: 0;
  //     padding: 0;
  //   }
  // }
  .btn-secondary {
    background-color: $retroSecondaryBG !important;
    border-color: $retroSecondaryBG !important;
    color: #fff !important;
    &:hover {
      background-color: $retroSecondaryBGHover !important;
      border-color: $retroSecondaryBGHover !important;
      color: $retroPrimaryTextColor !important;
    }
    // &:disabled {
    //   background-color: #222;
    //   border-color: #000;
    //   color: 0;
    // }
  }


  .print-cards {
    .print-section {
      .section-content {
        .lance-bonus {
          font-size: .7rem;
        }

      }
    }
  }

  a {
    color: #fff !important;
    &:hover {
      color: #eee !important;
    }
  }

  @keyframes blink {
    50% {
      border: dotted 2px lighten($retroPrimaryBG, 10);
      background-color: lighten($headerFooterBackground, 1);
    }
    // 50% {
    //   border: dotted 2px $retroPrimaryBG;
    //   background-color: $headerFooterBackground;
    // }
 }
  .alert {

    border: dotted 2px $retroPrimaryBG;
    background-color: $headerFooterBackground;
    color: $retroFontColor !important;
    animation: blink 1s step-end infinite alternate;
    p {
      color: $retroFontColor !important;
    }

  }

  table {
    border-top: dotted 2px $retroFontColor !important;
    thead, tbody, tfoot {
      tr {
        td, th {
          color: $retroFontColor;
          border-bottom: dotted 2px $retroPrimaryBG;
        }
      }
    }
    thead, tfoot {
      tr {
        th {
          color: $retroFontColor;
          border-top: dotted 2px $retroPrimaryBG;
        }
      }
    }
    tbody {
      border-bottom: dotted 2px $retroFontColor !important;
    }
  }

  .modal-dialog {
    // .modal-footer {
    //   background: $headerFooterBackground;
    // }
    .modal-header {
      // background: $headerFooterBackground;
      .close {
        color: $retroPrimaryBG;
        &:hover {
          color: $retroPrimaryBGHover;
        }
      }
    }
    .modal-content {
      border: solid 2px $retroPrimaryBG;
    }
  }

}

@media (min-width: 768px) {
  .content {
      position: relative !important;
      left: 0 !important;
      margin: 0 !important;
      top: 0 !important;
      padding: 0 2rem 5rem 2rem !important;
   }
}

@media (min-width: 2000px ) {
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 20%;
  }
}

@media print {
  @page { margin: 0; }
  .print-bar {
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .print-bg {
    background: transparent !important;
    padding: 0 !important;
    .print-page {
      background: transparent !important;
      margin: 0 auto !important;
      padding: 2% 0 !important;
      width: 95%;
      height: 95%;
      border: none !important;

      svg {
        max-width: 100% !important;
        max-height: 100% !important;
      }
    }
  }
}
