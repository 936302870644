.armor-location-select {
    position: relative;
    text-align: left;
    padding-bottom: 5rem;
    width: 250px;
    margin: 0 auto;

    svg {
        width: 250px;
        height: 335px;
    }
    .armor-select-dropdown {
        position: absolute;
        text-align: center;
        // border: solid 1px #900;
        display: inline-block;
        width: 4rem;
        select {
            display: inline-block;
            width: 4rem;
            font-size: 1rem;
            padding: 0;
            text-align: center;
        }
        .title {
            font-size: .8rem;
        }
        &.hd {
            left: 85px;
            top: -4px;
        }

        &.ct {
            left: 85px;
            top: 65px;
        }
        &.rt {
            left: 145px;
            top: 22px;
        }
        &.lt {
            left: 35px;
            top: 22px;
        }
        &.ra {
            left: 195px;
            top: 65px;
        }
        &.la {
            left: -10px;
            top: 65px;
        }
        &.rl {
            left: 154px;
            top: 242px;
        }
        &.ll {
            left: 27px;
            top: 242px;
        }

        &.ctr {
            left: 85px;
            top: 160px;
        }
        &.rtr {
            left: 143px;
            top: 110px;
        }
        &.ltr {
            left: 40px;
            top: 110px;
        }
    }
    &.quad {
        .armor-select-dropdown {
            &.hd {
                left: 92px;
                top: 21px;
            }

            &.ct {
                left: 92px;
                top: 83px;
            }
            &.rt {
                left: 162px;
                top: 22px;
            }
            &.lt {
                left: 22px;
                top: 26px;
            }
            &.rfl {
                left: 165px;
                top: 165px;
            }
            &.lfl {
                left: 20px;
                top: 165px;
            }
            &.rrl {
                left: 125px;
                top: 254px;
            }
            &.lrl {
                left: 63px;
                top: 254px;
            }

            &.ctr {
                left: 92px;
                top: 137px;
            }
            &.rtr {
                left: 162px;
                top: 90px;
            }
            &.ltr {
                left: 22px;
                top: 90px;
            }
        }
    }

    .armor-breakdown {
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: .8rem;
        z-index: 100;
    }

}

@media (min-width: 768px) {

    .armor-location-select {
        position: relative;
        text-align: left;
        width: 422px;
        svg {
            height: 565px;
            width: 422px;
        }

        .armor-select-dropdown {
            position: absolute;
            text-align: center;
            // border: solid 1px #900;
            display: inline-block;
            width: 4rem;
            .title {
                font-size: 1rem;
            }

            &.hd {
                left: 177px;
                top: 65px;
            }

            &.ct {
                left: 177px;
                top: 150px;
            }
            &.rt {
                left: 250px;
                top: 110px;
            }
            &.lt {
                left: 105px;
                top: 110px;
            }
            &.ra {
                left: 325px;
                top: 140px;
            }
            &.la {
                left: 32px;
                top: 140px;
            }
            &.rl {
                left: 281px;
                top: 465px;
            }
            &.ll {
                left: 75px;
                top: 465px;
            }

            &.ctr {
                left: 177px;
                top: 265px;
            }
            &.rtr {
                left: 243px;
                top: 245px;
            }
            &.ltr {
                left: 109px;
                top: 245px;
            }

        }

        &.quad {
            .armor-select-dropdown {
                &.hd {
                    left: 177px;
                    top: 80px;
                }

                &.ct {
                    left: 177px;
                    top: 163px;
                }
                &.rt {
                    left: 270px;
                    top: 75px;
                }
                &.lt {
                    left: 87px;
                    top: 75px;
                }
                &.rfl {
                    left: 300px;
                    top: 305px;
                }
                &.lfl {
                    left: 58px;
                    top: 305px;
                }
                &.rrl {
                    left: 239px;
                    top: 425px;
                }
                &.lrl {
                    left: 118px;
                    top: 425px;
                }

                &.ctr {
                    left: 177px;
                    top: 250px;
                }
                &.rtr {
                    left: 270px;
                    top: 175px;
                }
                &.ltr {
                    left: 87px;
                    top: 175px;
                }
            }
        }

        .armor-breakdown {
            position: absolute;
            bottom: 0;
            left: 20px;
            font-size: .8rem;
        }

    }

}