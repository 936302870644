.section-content {
    padding: 0 1rem !important;
}

.mp-section {
    padding: 0 1rem !important;
}

.deployment-description{
    min-height: 5rem;
}