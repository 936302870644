$fontSize: 14px;

$lightBG: #fdfde3;
$lightBGHover: #cacab4;
$desaturatedLightBG: #ccc;
$desaturatedLightBGHover: #ddd;
$retroLightBG: #030;
$retroLightBGHover: #040;

$colorLightGray: #eee;

// $colorTan: rgb(233, 226, 190);
$colorTan: $lightBG;

$fontColor: #fdfde3;
$desaturatedFontColor: #fefefe;
$retroFontColor: #0c0;

$headerFooterBackground: #111;
$headerFontColor: #fff;
$bodyBackground: #333;

$primaryBG: #dfab2d;
$primaryBGHover: #b48a25;
$primaryTextColor: #fff;
$colorWhite: #fff;
$desaturatedPrimaryBG: #666;
$desaturatedPrimaryBGHover: #999;
$desaturatedPrimaryTextColor: #fff;

$retroPrimaryBG: #090;
$retroPrimaryBGHover: #0c0;
$retroPrimaryTextColor: #000;

$disabledTextColor: #999;

$darkenedBG: rgba( 0, 0, 0, .25);
$darkenedGreenBG: rgba( 0, 66, 0, .25);

$secondaryBG: #725616;
$secondaryBGHover: #88671c;
$secondaryTextColor: #333;

$desaturatedSecondaryBG: #444;
$desaturatedSecondaryBGHover: #666;
$desaturatedSecondaryTextColor: #fff;

$retroSecondaryBG: #060;
$retroSecondaryBGHover: #080;
$retroSecondaryTextColor: #fff;

$textSectionBG: #222;
$textSectionFG: #fff;

$colorRed: #900;
$colorBrighterRed: #c00;
$colorBrightRed: #f00;

$colorBrightOrange: rgb(255, 174, 0);
$colorBlue: #00e;
$colorGreen: #090;