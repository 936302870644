.news {
    list-style: none;
    margin: 0;
    padding: 0;
    > li {
        margin: .25rem 1rem;
        padding: .25rem 1rem;
        border-top: solid 1px #fff;
        &:first-child {
            border-top: none;
        }
        p:last-child {
            margin-bottom: 0;
        }
    }
}